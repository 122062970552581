
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const MissingProducts = ({data,message}) => {

  return (
    <TableContainer
      component={Paper}
      sx={{
        p: 2,
        maxWidth: "100%",
        backgroundColor: "tableColor.main",
        position: "relative",
        maxHeight: "450px",
        overflowY: "scroll",
        border: "1px solid",
        borderColor: "secondary.second",
      }}
    >
      {message && (
        <Typography color="secondary.second">*{message} !!!</Typography>
      )}
      {data.length > 0 ? (
        <Table
          sx={{
            color: "secondary.second",
            "& .MuiTableCell-root": {
              color: "secondary.second !important",
            },
            "& .MuiSvgIcon-root": {
              color: "secondary.second !important",
            },
            "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
              color: "secondary.second !important",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Ürünler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1} - {item}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        ""
      )}
    </TableContainer>
  );
};

export default MissingProducts;
