import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  Box,
  Typography,
  TextField,
  useTheme,
  Divider,
} from "@mui/material";
import useAxios from "../../hooks/useAxios";
import { notify } from "../../helper/notify";
import MyButton from "../Commons/MyButton";
import CloseIcon from "@mui/icons-material/Close";
import { btnStyle } from "../Tables/EslesmisUrunlerTable";

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#454F5B"
      : state.isFocused
      ? "#D3D3D3"
      : "#fff",
    color: state.isSelected ? "#fff" : "#454F5B",
    padding: 10,
  }),
};

function UrunEslestirme({
  currentTCKN,
  getData,
  editingData = null,
  isEditing = false,
  setCounterUrun,
}) {
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [soldMaterials, setSoldMaterials] = useState([]);
  const [selectedSoldMaterials, setSelectedSoldMaterials] = useState(null);
  const axiosWithToken = useAxios();
  const theme = useTheme();
  useEffect(() => {
    if (currentTCKN) {
      fetchMaterials(currentTCKN);
      fetchSoldMaterials(currentTCKN);
    }
    // Initialize form with editing data if editing
    if (isEditing && editingData) {
      setSelectedSoldMaterials({
        value: editingData.name,
        label: editingData.name,
        quantity: editingData.quantity,
      });
      setSelectedMaterials(
        editingData.materials_used.map((material) => ({
          value: material.name,
          label: material.name,
          quantity: material.quantity_used,
        }))
      );
    } else {
      // Reset form if not editing
      setSelectedMaterials([]);
      setSelectedSoldMaterials(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTCKN, editingData, isEditing]);

  const fetchMaterials = async (currentTCKN) => {
    try {
      const response = await axiosWithToken.get(
        `api/indirilecek_KDV/get_material_name/${currentTCKN}`
      );
      const formattedMaterials = response.data.data.map((item) => ({
        value: item["URUN ADI"],
        label: item["URUN ADI"],
        quantity: 1.0,
      }));
      setMaterials(formattedMaterials);
    } catch (error) {
      console.error("Malzemeler çekilirken bir hata oluştu:", error);
    }
  };

  const fetchSoldMaterials = async (currentTCKN) => {
    try {
      const response = await axiosWithToken.get(
        `api/indirilecek_KDV/get_material_sold/${currentTCKN}`
      );
      const formattedSoldMaterials = response.data.data.map((item) => ({
        value: item["URUN ADI"],
        label: item["URUN ADI"],
        quantity: 1.0,
      }));
      setSoldMaterials(formattedSoldMaterials);
    } catch (error) {
      console.error("Satılan malzemeler çekilirken bir hata oluştu:", error);
    }
  };

  const handleMaterialChange = (selectedOptions) => {
    setSelectedMaterials(selectedOptions || []);
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedMaterials = selectedMaterials.map((material, idx) =>
      idx === index ? { ...material, quantity: newQuantity } : material
    );
    setSelectedMaterials(updatedMaterials);
  };

  const handleSoldMaterialChange = (selectedOption) => {
    setSelectedSoldMaterials(selectedOption);
  };

  const handleSubmit = async () => {
    try {
      if (editingData?.id) {
        const payload = {
          sold_material: {
            id: editingData.id,
            name: selectedSoldMaterials.label,
            quantity: Number(selectedSoldMaterials.quantity),
            materials_used: selectedMaterials.map((material) => ({
              name: material.label,
              quantity: Number(material.quantity),
            })),
          },
        };
        const response = await axiosWithToken.put(
          `api/indirilecek_KDV/update_product_materials/${currentTCKN}/`,
          payload
        );
        notify(response.data?.message, "success");
      } else {
        const payload = {
          customerTCKN: currentTCKN,
          soldMaterials: selectedSoldMaterials?.label
            ? [
                {
                  name: selectedSoldMaterials.label,
                  quantity: Number(selectedSoldMaterials.quantity),
                  materials: selectedMaterials.map((material) => ({
                    name: material.label,
                    quantity: Number(material.quantity),
                  })),
                },
              ]
            : null,
        };
        const response = await axiosWithToken.post(
          "api/indirilecek_KDV/save_product_creation/",
          payload
        );
        notify(response.data?.message, "success");
      }
      if(setCounterUrun) setCounterUrun((prev) => prev + 1);
      setSelectedMaterials([]);
      setSelectedSoldMaterials(null);
      if (!getData) {
        // Optionally fetch data again if needed
      } else {
        getData();
      }
    } catch (error) {
      console.error("Hata:", error);
    }
  };
const handleDeleteMaterial = (index) => {
  const updatedMaterials = selectedMaterials.filter((_, idx) => idx !== index);
  setSelectedMaterials(updatedMaterials);
};
  const animatedComponents = makeAnimated();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        border: `1px solid ${theme.palette.secondary.second}`,
        borderRadius: "0.2rem",
        p: 2,
        minHeight: "100%",
        backgroundColor: "tableColor.main",
      }}
    >
      <Typography variant="h5" color="secondary.second">
        İmalat Ürün Oluşturma Sihirbazı
      </Typography>
      <Typography variant="h6" color="secondary.second">
        Ürün/Product (Satışlar)
      </Typography>
      <Select
        components={animatedComponents}
        options={soldMaterials}
        value={selectedSoldMaterials}
        onChange={handleSoldMaterialChange}
        placeholder="Satılan malzemeyi seçin"
        menuPlacement="top"
        styles={customStyles}
      />
      <Divider />
      <Typography variant="h6" color="secondary.second">
        Hammadde (Alışlar)
      </Typography>
      <Select
        components={animatedComponents}
        isMulti
        options={materials}
        value={selectedMaterials}
        onChange={handleMaterialChange}
        placeholder="Malzemeleri seçin"
        menuPlacement="top"
        styles={customStyles}
      />
      {/* {selectedMaterials.map((material, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography sx={{ mr: 2 }} color="secondary.second">
            {material.label}
          </Typography>
          <TextField
            label="Miktar"
            type="number"
            size="small"
            inputProps={{ min: 1 }}
            value={material.quantity}
            onChange={(e) =>
              handleQuantityChange(index, parseFloat(e.target.value))
            }
            sx={{ width: "100px", color: "secondary.second" }}
          />
        </Box>
      ))} */}
      <Box
        className="myscrool"
        sx={{
          maxHeight: "150px",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 2,
        }}
      >
        {selectedMaterials.map((material, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ mr: 2, flexGrow: 1 }} color="secondary.second">
              {material.label}
            </Typography>
            <TextField
              label="Miktar"
              type="number"
              size="small"
              inputProps={{ min: 1, step: 0.1 }}
              value={material.quantity}
              onChange={(e) =>
                handleQuantityChange(index, parseFloat(e.target.value))
              }
              sx={{ width: "75px", color: "secondary.second" }}
            />
            <CloseIcon
              color="error"
              onClick={() => handleDeleteMaterial(index)}
              sx={{ ml: 2, ...btnStyle }}
            />
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          width: "25%",
          display: "flex",
          m: "auto",
        }}
      >
        <MyButton
          variant="contained"
          color="btnActiveColor"
          onClick={handleSubmit}
          title={editingData?.id ? "Güncelle" : "Kaydet"}
        />
      </Box>
    </Box>
  );
}

export default UrunEslestirme;
