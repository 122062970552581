/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageHeader from "../components/Commons/PageHeader";
import MyButton from "../components/Commons/MyButton";
import CustomerRegistrationModal from "../components/Forms/CustomerForm";
import { newCustomer } from "../helper/initialData";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import CustomerSelect from "../components/Commons/CustomerSelect";
import Loading from "../components/Commons/Loading";
import IndirilecekKDVListesi from "../components/IadeTurleriIslemleri/IndirilecekKDVListesi";
import TevkifatliSatisFaturalariListesi from "../components/IadeTurleriIslemleri/TevkifatliSatisFaturalariListesi";
import { useGetCustomersQuery } from "../services/customers";

const IslemMerkezi = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { isLoading: loading } = useGetCustomersQuery();
  const [selectedMusteri, setSelectedMusteri] = useState("");
  const [show, setShow] = useState({
    indirilecekKDV: false,
    yuklenilecekKDV: false,
    satisFaturalari: false,
    iadeyeEsasKDV: false,
  });

  const handleShow = (type) => {
    setShow((prev) => ({
      ...prev,
      indirilecekKDV: type === "indirilecekKDV",
      satisFaturalari: type === "satisFaturalari",
    }));
  };
  
  useEffect(() => {
    if (!selectedMusteri) {
      setShow({
        indirilecekKDV: false,
        satisFaturalari: false,
      });
    }
  }, [selectedMusteri]);

  if (loading) return <Loading />;
  return (
    <Box sx={{ p: 1 }}>
      <PageHeader text="Mal İhracatı" />

      <Grid container spacing={3} flexDirection={"column"} mt={1}>
        <Grid item xs={8} md={3}>
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={handleOpen}
            title="Yeni Mükellef Kaydı Oluştur"
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <CustomerSelect
            selectedMusteri={selectedMusteri}
            setSelectedMusteri={setSelectedMusteri}
            msg="İşlem yapmak istediğiniz müşteriyi seçiniz..."
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1, gap: 1 }}>
        {selectedMusteri && (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <MyButton
                variant={show.indirilecekKDV ? "contained" : "outlined"}
                color={show.indirilecekKDV ? "btnActiveColor" : "btnColor"}
                title="Indirilecek KDV Listesi"
                onClick={() => handleShow("indirilecekKDV")}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <MyButton
                variant={show.satisFaturalari ? "contained" : "outlined"}
                color={show.satisFaturalari ? "btnActiveColor" : "btnColor"}
                title="Tevkifatlı Satış Faturaları Listesi"
                onClick={() => handleShow("satisFaturalari")}
              />
            </Box>
          </>
        )}
      </Box>

      {show.indirilecekKDV && (
        <IndirilecekKDVListesi tckn={selectedMusteri?.value} />
      )}
      {show.satisFaturalari && (
        <TevkifatliSatisFaturalariListesi tckn={selectedMusteri?.value} />
      )}

      {open && (
        <CustomerRegistrationModal
          isVisible={open}
          toggleModal={handleClose}
          initialState={newCustomer}
        />
      )}
    </Box>
  );
};

export default IslemMerkezi;
