import React, { useState } from 'react'
import { Box } from '@mui/material';
import MyButton from './MyButton';
import useAxios from '../../hooks/useAxios';
import { notify } from '../../helper/notify';

const ExportData = ({url,dosyaAdi}) => {
  const axiosWithToken = useAxios()
  const [loading,setLoading] = useState(false)
  const handleExport = async ()=>{
    setLoading(true)
    try {
      const response = await axiosWithToken.get(url, {
        responseType: "blob",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // Eğer gerekirse Content-Type da eklenebilir, ancak GET isteklerinde genellikle gerekli değildir
          // 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
      });
      const blob = response.data;
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", dosyaAdi); // İndirilecek dosyanın adı
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      
    } catch (error) {
      console.error("İstek sırasında bir hata oluştu:", error);
      notify("İşlem sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyiniz!","error")
    } finally{
      setLoading(false)
    }
  }
    
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        "& .Mui-disabled": {
          backgroundColor: "rgb(0, 146, 202,0.7)",
          color: "secondary.second",
          borderColor: "secondary.second",
        },
      }}
    >
      {loading ? (
        <MyButton
          variant="outlined"
          color="btnActiveColor"
          type="button"
          disabled
          // startIcon={<CircularProgress />}
          title={"Hazırlanıyor..."}
        />
      ) : (
        <MyButton
          variant="contained"
          color="btnActiveColor"
          title="Dışa Aktar"
          onClick={handleExport}
        />
      )}
    </Box>
  );
}

export default ExportData