import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, TablePagination } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import useAxios from "../../hooks/useAxios";
import LoadingData from "../Commons/LoadingData";
import UrunEslestirme from "../Forms/UrunEslestirme";
import { notify } from "../../helper/notify";
import ErrorData from "../Commons/ErrorData";

export const btnStyle = {
  cursor: "pointer",
  color: "secondary.second",
  "&:hover": {
    color: "red",
  },
};

function Row(props) {
  const { row, setSelectedRow, deleteEslesenData } = props;
  const [open, setOpen] = React.useState(false);

  const handleEditClick = () => {
    setSelectedRow(row); 
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell align="center">{row.quantity}</TableCell>
        <TableCell align="center">
          <EditIcon sx={btnStyle} onClick={handleEditClick} />
          <DeleteOutlineIcon
            sx={btnStyle}
            onClick={() => deleteEslesenData(row.id)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Kullanılan Malzemeler
              </Typography>
              <Table size="small" aria-label="materials used">
                <TableHead>
                  <TableRow>
                    <TableCell>Malzeme Adı</TableCell>
                    <TableCell align="center">Kullanılan Miktar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.materials_used.map((material) => (
                    <TableRow key={material.id}>
                      <TableCell component="th" scope="row">
                        {material.name}
                      </TableCell>
                      <TableCell align="center">
                        {material.quantity_used}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function EslesmisUrunlerTable({ tckn, setCounterUrun }) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [eslesenData, setEslesenData] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const axiosWithToken = useAxios();
  const [errorEslesen, setErrorEslesen] = useState(false);
  const [loadingEslesen, setLoadingEslesen] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getData = async () => {
    setLoadingEslesen(true);
    setErrorEslesen(false);
    setEslesenData({});
    try {
      const { data } = await axiosWithToken.get(
        `api/indirilecek_KDV/get_product_materials/${tckn}`
      );
      setEslesenData(data.data);
    } catch (error) {
      setErrorEslesen(error?.response?.data?.error);
      setEslesenData({});
    }finally {
      setLoadingEslesen(false)
    }
  }
  useEffect(() => {
    if (tckn) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tckn]);
  const deleteEslesenData = async (id) => {
    try {
      await axiosWithToken.delete(
        `api/indirilecek_KDV/delete_sold_material/${tckn}/${id}/`
      );
      getData();
      setCounterUrun((prev) => prev + 1);
      notify("Başarıyla silindi!", "success");
    } catch (error) {
      notify("İşlem başarısız oldu!", "error");
    }
  };
  // if (!eslesenData.customer_tckn) return <LoadingData />;
  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        spacing={3}
        mt={2}
        mb={3}
        sx={{ minHeight: 400 }}
      >
        {!loadingEslesen && !errorEslesen && eslesenData.customer_tckn ? (
          <Grid item xs={10} md={6}>
            <Typography>Eşleşen Ürünler Listesi</Typography>
            <TableContainer
              component={Paper}
              color="secondary.second"
              sx={{
                minHeight: "100%",
                backgroundColor: "tableColor.main",
                color: "secondary.second",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Table
                aria-label="collapsible table"
                sx={{
                  color: "secondary.second",
                  "& .MuiTableCell-root": {
                    color: "secondary.second !important",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "secondary.second !important",
                  },
                  "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
                    color: "secondary.second !important",
                  },
                }}
              >
                <TableHead>
                  <TableRow sx={{ color: "secondary.second" }}>
                    <TableCell />
                    <TableCell align="center">Eşleştirilen Ürün Adı</TableCell>
                    <TableCell align="center">Miktar</TableCell>
                    <TableCell align="center">İşlemler</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ color: "secondary.second" }}>
                  {(rowsPerPage > 0
                    ? eslesenData?.sold_materials &&
                      eslesenData?.sold_materials?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : eslesenData?.sold_materials || []
                  ).map((row, i) => (
                    <Row
                      key={i}
                      row={row}
                      setSelectedRow={setSelectedRow}
                      deleteEslesenData={deleteEslesenData}
                    />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={eslesenData?.sold_materials?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Satır sayısı:"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} / ${count !== -1 ? count : `daha fazla ${to}`}`
                }
                sx={{
                  color: "secondary.second",
                  "& .MuiTablePagination-selectIcon": {
                    color: "secondary.second !important",
                  },
                }}
              />
            </TableContainer>
          </Grid>
        ) : (
          <Grid item xs={10} md={6}>
            <Typography>Eşleşen Ürünler Listesi</Typography>
            {loadingEslesen ? <LoadingData /> : ""}
            {errorEslesen ? <ErrorData error={errorEslesen} /> : ""}
          </Grid>
        )}
        <Grid item xs={10} md={6} sx={{mt:{xs:2,md:0}}}>
          <Typography>Ürün Eşleştirme</Typography>
          <UrunEslestirme
            currentTCKN={tckn}
            getData={getData}
            editingData={selectedRow}
            isEditing={true}
            setCounterUrun={setCounterUrun}
          />
        </Grid>
      </Grid>
    </>
  );
}
