import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import "dayjs/locale/tr";
import MyButton from "../Commons/MyButton";
import { notify } from "../../helper/notify";
import useAxios from "../../hooks/useAxios";
import TableComponent from "../Tables/TableComponent";
import { indirilecekKDVColumns } from "../../helper/columns";
import ExportData from "../Commons/ExportData";
import EnvanterKaydi from "../Islemler/EnvanterKaydi";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";
import KarsitIncelemeListesi from "../Tables/KarsitIncelemeListesi";
import DateComp from "../Commons/DateComp";
import { aylar } from "../../helper/initialData";
import KDVBeyannamesiKaydi from "../Islemler/KDVBeyannamesiKaydi";
import ShowKDVBeyannameOzet from "../Islemler/ShowKDVBeyannameOzet";






const IndirilecekKDVListesi = ({ tckn }) => {
  const [indirilecekKDVData, setIndirilecekKDVData] = useState([]);
  const [karsitIncelemeData, setKarsitIncelemeData] = useState([]);
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [exportDate, setExportDate] = useState({});
  const axiosWithToken = useAxios();
  const getData = async (year, month) => {
    setError(false);
    if (year && month) {
      setExportDate({ year, month });
      setLoading(true);
      setIndirilecekKDVData([]);
      try {
        const { data } = await axiosWithToken(
          `api/indirilecek_KDV/get_data_show/${tckn}?start_date=${year}-${month}-01&end_date=${year}-${month}-${aylar[month]}`
        );
        const transformedData = data.data.map((item) => ({
          faturaTarihi: item["FATURA TARIHI"],
          Alisfaturasininserisi: item["Alış Faturasının Serisi"],
          faturaNo: item["FATURA SIRA NO"],
          saticiAdi: item["SATICI ADI"],
          saticiVknTckn: item["SATICI VKN/TCKN"],
          malinCinsi: item["MALIN CINSI"],
          miktar: item["MIKTAR"],
          malHizmetToplamTutar: item["MAL HIZMET TOPLAM TUTAR"],
          toplamKdvTutari: item["TOPLAM KDV TUTARI"],
          gcbtescilnosu: item["GÇB Tescil No'su (Alış İthalat İse)"],
          tabiolunandonem:
            item["Belgenin İndirim Hakkının Kullanıldığı KDV Dönemi"],
        }));
        setIndirilecekKDVData(transformedData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error?.response?.data?.error);
        setIndirilecekKDVData([]);
      }
    } else {
      notify("Lütfen önce tarih aralığı belirleyiniz!", "warning");
    }
  };

  useEffect(() => {
    setIndirilecekKDVData([]);
    setKarsitIncelemeData([]);
  }, [tckn])
  

  return (
    <Box sx={{ p: 1 }}>
      <Typography color="error" variant="body2" mb={1}>
        *Eğer daha önce fatura kaydı yükleme işlemi yapmadıysanız lütfen önce
        yükleme işlemini tamamlayınız!
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1, gap: 1 }}>
      <Box className="myscrool" sx={{ maxWidth: "100%", overflowY: "scroll" }}>
        {" "}
        <EnvanterKaydi tckn={tckn} />
      </Box>
      <Box className="myscrool" sx={{ maxWidth: "100%", overflowY: "scroll" }}>
        <KDVBeyannamesiKaydi tckn={tckn} getIadeyeEsasKDV={getData} />
      </Box>
      <Box className="myscrool" sx={{ maxWidth: "100%", overflowY: "scroll" }}>
        <ShowKDVBeyannameOzet tckn={tckn} showBtn={true} />
      </Box>
      </Box>

      {/* <DateRangecomp getData={getData} title="Indirilecek KDV Listesi" /> */}
      <DateComp getData={getData} title={"İndirilecek KDV Listesi"} />
      {loading ? <LoadingData /> : ""}
      {error ? <ErrorData error={error} /> : ""}
      {indirilecekKDVData.length > 0 ? (
        <>
          <Typography
            component={"h3"}
            variant={"h5"}
            align="center"
            color="secondary.second"
            mt={2}
          >
            İndirilecek KDV Listesi
          </Typography>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "secondary.second",
              p: 1,
              mt: 2,
            }}
          >
            <TableComponent
              data={indirilecekKDVData}
              tableColumns={indirilecekKDVColumns}
            />
            <Grid container display={"flex"} justifyContent="end" spacing={1}>
              <Grid item>
                <ExportData
                  url={`api/indirilecek_KDV/get_alislar/${tckn}?start_date=${
                    exportDate?.year
                  }-${exportDate?.month}-01&end_date=${exportDate?.year}-${
                    exportDate?.month
                  }-${aylar[exportDate?.month]}`}
                  dosyaAdi={`indirilecek_KDV_listesi-${tckn}-${exportDate?.year}-${exportDate?.month}.xlsx`}
                />
              </Grid>
              <Grid item>
                <MyButton
                  title={"Kapat"}
                  variant="contained"
                  color="btnActiveColor"
                  onClick={() => setIndirilecekKDVData([])}
                >
                  Kapat
                </MyButton>
              </Grid>
            </Grid>
          </Box>

          <KarsitIncelemeListesi
            karsitIncelemeData={karsitIncelemeData}
            aliciVknTckn={tckn}
            setKarsitIncelemeData={setKarsitIncelemeData}
            year={exportDate?.year}
            month={exportDate?.month}
          />
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default IndirilecekKDVListesi;
