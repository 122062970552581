import LockIcon from "@mui/icons-material/Lock";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AuthHeader from "../components/Commons/AuthHeader";
import AuthImage from "../components/Commons/AuthImage";
import useAuthCall from "../hooks/useAuthCall";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { notify } from "../helper/notify";
const Login = ({colorMode,theme})=>{
  const { login, forgotPassword } = useAuthCall();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleForgot = () => {
    if(values.email){
      forgotPassword(values.email)
    }else{
      notify("Lütfen önce yukarıdaki email kutucuğuna kayıtlı olduğunuz mail adresinizi giriniz!","warning")
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    login(values);
    setValues({
      email: "",
      password: "",
    });
  };

  return (
    <Container maxWidth="2xl">
      <Grid
        container
        justifyContent="center"
        direction="row-reverse"
        sx={{
          height: "100vh",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Box
            sx={{ ml: 1, my: 2, cursor: "pointer" }}
            onClick={colorMode.toggleColorMode}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </Box>
        </Box>
        <AuthHeader />

        <Grid item xs={12} sm={10} md={6}>
          <Avatar
            sx={{
              backgroundColor: "bodyColor.main",
              m: "auto",
              width: 40,
              height: 40,
            }}
          >
            <LockIcon size="30" />
          </Avatar>
          <Typography
            variant="h4"
            align="center"
            mb={4}
            color="secondary.second"
          >
            Giriş Yap
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "secondary.second !important",
              },
              "& .MuiInputLabel-root": {
                color: "secondary.second !important",
              },
              "& .MuiInputBase-input": {
                color: "secondary.second !important",
              },
            }}
            component="form"
            onSubmit={handleSubmit}
          >
            <TextField
              label="Email"
              name="email"
              id="email"
              type="email"
              variant="outlined"
              color="input"
              onChange={handleChange}
              value={values.email}
              required
            />
            <TextField
              label="Şifre"
              name="password"
              id="password"
              type="password"
              variant="outlined"
              color="input"
              onChange={handleChange}
              value={values.password}
              required
            />
            <Box
              onClick={handleForgot}
              sx={{
                display: "flex",
                justifyContent: "end",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Şifremi Unuttum
            </Box>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#0092CA",
                color: "white",
                "&:hover": { backgroundColor: "rgb(0, 146, 202,0.6)" },
                textTransform: "none",
              }}
            >
              Giriş Yap
            </Button>
          </Box>

          <Box sx={{ textAlign: "center", mt: 2, color: "secondary.second" }}>
            <Typography
              component="a"
              href="https://musavironline.pythonanywhere.com/"
              target="true"
              sx={{ textDecoration: "none", color: "secondary.second" }}
            >
              Eğer henüz hesabınız yoksa size uygun paketlerimizi görüntülemek
              için{" "}
              <span style={{ textDecoration: "underline" }}>tıklayınız!</span>
            </Typography>
          </Box>
        </Grid>

        <AuthImage
          image={"https://altinyazilim.com/web/images/malimusavir-resim.jpg"}
        />
      </Grid>
    </Container>
  );
};

export default Login;