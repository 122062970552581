import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MyButton from "../Commons/MyButton";
import useAxios from "../../hooks/useAxios";
import { notify } from "../../helper/notify";

const message = {
  upload: "Dosyalarınız Yükleniyor...",
  process: "Dosyalarınız işleniyor...",
};

const MAX_FILES = 100; // Maximum number of files allowed
const MAX_TOTAL_SIZE_MB = 100; // Maximum total file size allowed in MB
const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024; // Convert MB to bytes

const UploadFilesForm = ({
  setData,
  tckn,
  url,
  secondUrl,
  folderType,
  title,
  getIslenmisFaturalar,
}) => {
  const [open, setOpen] = useState(false);
  const [fileBatchError, setFileBatchError] = useState("");
  const [loading, setLoading] = useState(false); // Changed initial state to false
  const [selectedFiles, setSelectedFiles] = useState([]); // State to hold the selected files
  const [dragActive, setDragActive] = useState(false); // State to handle drag and drop UI
  const axiosWithToken = useAxios();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFileBatchError(""); // Reset error message when dialog is closed
    setSelectedFiles([]); // Reset selected files
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files); // Directly set files without validation
    validateFiles(files); // Validate files after setting
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragActive(false);
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(files); // Directly set files without validation
    validateFiles(files); // Validate files after setting
  };

  const validateFiles = (files) => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);

    if (files.length > MAX_FILES) {
      setFileBatchError(
        `Maksimum ${MAX_FILES} dosya ve maksimum 100mb'a kadar dosya yükleyebilirsiniz. Lütfen fazlalıkları kaldırın.`
      );
    } else if (totalSize > MAX_TOTAL_SIZE_BYTES) {
      setFileBatchError(
        `Toplam dosya boyutu ${MAX_TOTAL_SIZE_MB} MB'ı aşamaz.`
      );
    } else {
      setFileBatchError(""); // Clear error message if all conditions are met
    }
  };

  const handleRemoveFile = (indexToRemove) => {
    if (!loading) {
      // Prevent file removal if loading
      const updatedFiles = selectedFiles.filter(
        (_, index) => index !== indexToRemove
      );
      setSelectedFiles(updatedFiles);
      validateFiles(updatedFiles); // Re-validate files after removing one
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileBatchError || selectedFiles.length === 0) {
      notify("Lütfen geçerli dosyalar seçiniz ve yeniden deneyin.", "error");
      return; // Prevent form submission if there's an error or no valid files selected
    }

    setLoading("upload");

    try {
      // Upload all selected files
      const data = new FormData();
      selectedFiles.forEach((file) => data.append("files", file));
      data.append("tckn", tckn);
      data.append("folder_type", folderType);

      const res = await axiosWithToken.post(url, data);

      if (res.status === 200) {
        setLoading("process");
        const response = await axiosWithToken.post(secondUrl, {
          tckn,
          folder_type: folderType,
        });


        if (response.status === 200) {
          handleApiResponse(response);
          notify("Dosyalarınız başarıyla yüklendi ve işlendi.", "success");
        } else {
          notify(
            "Dosyalar yüklendi ancak veri işleme sırasında bir hata oluştu!",
            "error"
          );
        }
      } else {
        notify("Dosyalarınızın yüklenmesi sırasında bir hata oluştu!", "error");
      }

      handleClose();
    } catch (error) {
      notify("Dosyalarınızın yüklenmesi sırasında bir hata oluştu!", "error");
    } finally {
      setLoading(false); // Ensure loading is set to false after processing
    }
  };

  const handleApiResponse = async (response) => {
    if (folderType === "fatura") {
      if (getIslenmisFaturalar) {
        getIslenmisFaturalar();
      } else {
        setData({
          alislarTemelData: JSON.parse(response.data.alislar_temel),
          alislarIstisnaData: JSON.parse(response.data.alislar_istisna),
          satislarTemelData: JSON.parse(response.data.satislar_temel),
          satislarIstisnaData: JSON.parse(response.data.satislar_istisna),
        });
      }
    } else if (folderType === "gcb") {
      setData(JSON.parse(response.data.gcbler));
    } else if (folderType === "beyanname") {
      setData(true);
    }
  };

  return (
    <>
      <MyButton
        variant="contained"
        color="btnActiveColor"
        onClick={handleOpen}
        title={title}
      />
      <Dialog open={open}>
        <DialogTitle>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2">Mükellef TCKN/VKN : {tckn}</Typography>
        </DialogTitle>

        <DialogContent>
          <Typography color="error" variant="body2">
            *Tek seferde maksimum 100 dosya yükleyebilirsiniz!
          </Typography>
          <form
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            style={{ display: "flex", flexDirection: "column", gap: 3 }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <Box
              onClick={() =>
                !loading && document.getElementById("fileInput").click()
              } // Prevent file dialog opening if loading
              sx={{
                border: "2px dashed",
                borderColor: dragActive ? "#1976d2" : "secondary.second",
                padding: "16px",
                borderRadius: "8px",
                textAlign: "center",
                cursor: "pointer",
                mb: 2,
              }}
            >
              <Typography color="secondary.second" variant="body1">
                Dosyaları buraya sürükleyin veya yüklemek için tıklayınız!
              </Typography>
              <Typography color="secondary.second" variant="body2">
                {selectedFiles.length > 0
                  ? `${selectedFiles.length} dosya seçildi`
                  : ""}
              </Typography>
              <input
                type="file"
                multiple
                accept={folderType === "yuklenim" ? "*" : ".xml"}
                style={{ display: "none" }}
                id="fileInput"
                onChange={handleFileChange}
                disabled={Boolean(loading)} // Disable file input if loading
              />
            </Box>

            <Typography color="secondary.second" variant="body2">
              *Seçtiğiniz dosyaların işlenebilmesi için Yükle butonuna
              tıklayınız!
            </Typography>

            {/* Display the list of selected files with delete icon */}
            {selectedFiles.length > 0 && (
              <List
                className="myscrool"
                sx={{ maxHeight: "75px", overflowY: "scroll" }}
              >
                {selectedFiles.map((file, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemoveFile(index)}
                        disabled={Boolean(loading)} // Disable remove button if loading
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={file.name} />
                  </ListItem>
                ))}
              </List>
            )}

            {fileBatchError && (
              <Typography color="error" variant="body2">
                {fileBatchError}
              </Typography>
            )}
            {loading ? (
              <>
                <MyButton
                  size="normal"
                  variant="contained"
                  type="button"
                  disabled
                  startIcon={<CircularProgress />}
                  title={message[loading]}
                />
                <Typography color="secondary.second" variant="body2">
                  İşlem süresi dosyaların boyutuna ve sayısına göre değişkenlik
                  gösterebilir. İşlem bittiğinde yükleme penceresi otomatik
                  olarak kapanacaktır.
                </Typography>
              </>
            ) : (
              <MyButton
                size="normal"
                variant="contained"
                color="btnActiveColor"
                type="submit"
                title="Yükle"
                disabled={!!fileBatchError || selectedFiles.length === 0} // Disable the button if there's an error or no valid files selected
              />
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="btnColor"
            disabled={Boolean(loading)}
          >
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadFilesForm;
