import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useAxios from "../../hooks/useAxios";
import MyButton from "../Commons/MyButton";
import AylıkKdvDetayTable from "./AylıkKdvDetayTable";
import LoadingData from "../Commons/LoadingData";
import { ArrowDownwardOutlined, ArrowUpward } from "@mui/icons-material";
import ErrorData from "../Commons/ErrorData";

const AylıkKDVTablo = ({ selectedMusteri }) => {
  const [aylikKDV, setAylikKDV] = useState([]);
  const [errorKDV, setErrorKDV] = useState(false);
  const [loadingKDV, setLoadingKDV] = useState(true);
  const axiosWithToken = useAxios();
  const [open,setOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState({});
const [order, setOrder] = useState("asc");

const handleRequestSort = () => {
    if (order === "asc") {
      setOrder("desc");
    } else if (order === "desc") {
      setOrder("asc");
    }
};

  const handleOpen = (year,month) => {
    setOpen(true);
    setSelectedRow({year,month})
  }

  const handleClose = (year, month) => {
    setOpen(false);
    setSelectedRow({});
  };

  useEffect(() => {
    if (selectedMusteri) {
      const fetchAylikKDV = async () => {
        const url = `api/customer_info/calculate_kdv/`;
        setErrorKDV(false);
        setLoadingKDV(true)
        setAylikKDV([]);
        try {
          const response = await axiosWithToken.post(url, {
            tckn: selectedMusteri?.value,
          });
          if (response.status === 200) {
            setAylikKDV(response.data?.kdv_results);
            setLoadingKDV(false);
          }
        } catch (error) {
          setErrorKDV(error.response.data.error);
          setLoadingKDV(false);
          setAylikKDV([]);
        }
      };

      fetchAylikKDV();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMusteri]);
  return (
    <>
      <Typography
        component={"h3"}
        variant={"h5"}
        align="center"
        color="secondary.second"
        mt={2}
      >
        Aylara Göre KDV Raporları
      </Typography>
      {aylikKDV.length ? (
        <TableContainer
          component={Paper}
          color="secondary.second"
          className="myscrool"
          sx={{
            mt: 1,
            maxWidth: "100%",
            backgroundColor: "tableColor.main",
            position: "relative",
            maxHeight: "450px",
            minHeight: "450px",
            overflowY: "scroll",
            py: 1,
          }}
        >
          <Table
            aria-label="simple table"
            sx={{
              color: "secondary.second",
              "& .MuiTableCell-root": {
                color: "secondary.second !important",
              },
              "& .MuiSvgIcon-root": {
                color: "secondary.second !important",
              },
              "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
                color: "secondary.second !important",
              },
            }}
          >
            <TableHead
              sx={{
                position: "sticky",
                top: "-15px",
                zIndex: 1,
                backgroundColor: "tableColor.main",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    minWidth: "50px",
                    maxWidth: "150px",
                  }}
                  align="left"
                  title="Yıl/Ay"
                  onClick={() => handleRequestSort("Yıl/Ay")}
                >
                  <span>Yıl/Ay</span>{" "}
                  {order === "asc" ? (
                    <ArrowUpward />
                  ) : (
                    <ArrowDownwardOutlined />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "100px",
                  }}
                  title="Hesaplanan KDV"
                  align="center"
                >
                  Hesaplanan KDV
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "100px",
                  }}
                  title="İndirilen KDV"
                  align="center"
                >
                  İndirilen KDV
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "100px",
                  }}
                  title="Net KDV"
                  align="center"
                >
                  Net KDV
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "100px",
                  }}
                  title="Detay"
                  align="center"
                >
                  Detay
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(order === "asc" ? aylikKDV : [...aylikKDV].reverse()).map(
                (item, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        minWidth: "50px",
                        maxWidth: "150px",
                      }}
                      align="center"
                      component="th"
                      scope="row"
                      title={`${item.year}/${item.month}`}
                    >
                      {item.year}/{item.month}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        minWidth: "50px",
                        maxWidth: "100px",
                      }}
                      title={item.total_purchase_kdv.toFixed(2)}
                      align="center"
                    >
                      {item.total_purchase_kdv.toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        minWidth: "50px",
                        maxWidth: "100px",
                      }}
                      title={item.total_sales_kdv.toFixed(2)}
                      align="center"
                    >
                      {item.total_sales_kdv.toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        minWidth: "50px",
                        maxWidth: "100px",
                        color:
                          item.net_kdv > 0
                            ? "green"
                            : item.net_kdv === 0
                            ? ""
                            : "red",
                      }}
                      title={item.net_kdv.toFixed(2)}
                      align="center"
                    >
                      {item.net_kdv.toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        minWidth: "50px",
                        maxWidth: "100px",
                      }}
                      align="center"
                    >
                      <MyButton
                        variant="contained"
                        color="btnActiveColor"
                        onClick={() => handleOpen(item.year, item.month)}
                        title="Daha Fazla"
                        width="auto"
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      {open && (
        <AylıkKdvDetayTable
          open={open}
          handleClose={handleClose}
          year={selectedRow.year}
          selectedMusteri={selectedMusteri}
          month={selectedRow.month}
        />
      )}
      {loadingKDV ? <LoadingData /> : ""}
      {errorKDV ? <ErrorData error={errorKDV} /> : ""}
      {!errorKDV && !aylikKDV.length && !loadingKDV ? (
        <Typography component={"h4"} color="secondary.second" m={3}>
          Görüntülenecek cari verisi bulunamadı. Müşteriniz için envanter kaydı
          güncellemesi yapabilirsiniz.
        </Typography>
      ) : (
        ""
      )}
    </>
  );
};

export default AylıkKDVTablo;
