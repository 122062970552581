/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import MyButton from "../components/Commons/MyButton";
import CustomerSelect from "../components/Commons/CustomerSelect";
import CustomerRegistrationModal from "../components/Forms/CustomerForm";
import { newCustomer } from "../helper/initialData";
import useAxios from "../hooks/useAxios";
import EnvanterTabs from "../components/Navigation/EnvanterTabs";
import Loading from "../components/Commons/Loading";
import ExportData from "../components/Commons/ExportData";
import ErrorData from "../components/Commons/ErrorData";
import LoadingData from "../components/Commons/LoadingData";
import EnvanterKaydi from "../components/Islemler/EnvanterKaydi";
import { useGetCustomersQuery } from "../services/customers";

const IslenmisFaturalar = () => {
  const [islenmisFaturalar, setIslenmisFaturalar] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {isLoading: loading } = useGetCustomersQuery();
  const [loadingFaturalar,setLoadingFaturalar] = useState(false)
  const [selectedMusteri, setSelectedMusteri] = useState("");
   const [error, setError] = useState(false);
  const axiosWithToken = useAxios();
  const handleCloseTableCustomer = () => {
    setIslenmisFaturalar({});
  };
  

  const getIslenmisFaturalar = async () => {
    setIslenmisFaturalar({});
    setLoadingFaturalar(true)
    setError(false);
    try {
      const { data } = await axiosWithToken.get(
        `api/reader_3/processed_invoices/${selectedMusteri?.value}`
      );
      setIslenmisFaturalar({
        alislarTemelData: JSON.parse(data[`Alışlar`]),
        alislarIstisnaData: JSON.parse(data[`Alışlar İstisna`]),
        satislarTemelData: JSON.parse(data[`Satışlar`]),
        satislarIstisnaData: JSON.parse(data[`Satışlar İstisna`]),
      });
    } catch (error) {
      setError(error?.response?.data?.error);
    }finally{
        setLoadingFaturalar(false)
    }
  };

  useEffect(() => {
    if (!selectedMusteri) {
      setIslenmisFaturalar({});
    } else {
      getIslenmisFaturalar();
    }
  }, [selectedMusteri]);
  if (loading) return <Loading />;
  return (
    <Box sx={{ p: 1 }}>
      <Grid container spacing={3} flexDirection={"column"} mt={1}>
        <Grid item xs={8} md={3}>
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={handleOpen}
            title="Yeni Mükellef Kaydı Oluştur"
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <CustomerSelect
            selectedMusteri={selectedMusteri}
            setSelectedMusteri={setSelectedMusteri}
            msg="İşlem yapmak istediğiniz müşteriyi seçiniz..."
          />
        </Grid>
      </Grid>
      <Box
        className="myscrool"
        sx={{ maxWidth: "100%", overflowY: "scroll", my: 2 }}
      >
        {" "}
        <EnvanterKaydi
          tckn={selectedMusteri?.value}
          getIslenmisFaturalar={getIslenmisFaturalar}
        />
      </Box>
      {loadingFaturalar ? <LoadingData /> : ""}
      {error ? <ErrorData error={error} /> : ""}
      {Object.values(islenmisFaturalar).length > 0 && (
        <>
          <EnvanterTabs envanterData={islenmisFaturalar} />
          <Grid container display={"flex"} justifyContent="end" spacing={1}>
            <Grid item>
              <ExportData
                url={`api/reader_3/processed_invoices/${selectedMusteri?.value}?excel=true`}
                dosyaAdi={`islenmis_faturalar-${selectedMusteri?.value}.xlsx`}
              />
            </Grid>
            <Grid item>
              <MyButton
                variant="contained"
                color="btnActiveColor"
                onClick={handleCloseTableCustomer}
                title="Kapat"
              />
            </Grid>
          </Grid>
        </>
      )}

      {open && (
        <CustomerRegistrationModal
          isVisible={open}
          toggleModal={handleClose}
          initialState={newCustomer}
        />
      )}
    </Box>
  );
};

export default IslenmisFaturalar;
