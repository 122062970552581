import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import MyButton from "../Commons/MyButton";
import { notify } from "../../helper/notify";
import useAxios from "../../hooks/useAxios";
import TableComponent from "../Tables/TableComponent";
import { yuklenilecekKDVColumns } from "../../helper/columns";
import ExportData from "../Commons/ExportData";
import EnvanterKaydi from "../Islemler/EnvanterKaydi";
import GcbKaydi from "../Islemler/GcbKaydi";
import YuklenimKaydi from "../Islemler/YuklenimKaydi";
import MissingProducts from "../Tables/MissingProducts";
import LoadingData from "../Commons/LoadingData";
import DateComp from "../Commons/DateComp";
import ErrorData from "../Commons/ErrorData";
import UrunEslestirme from "../Forms/UrunEslestirme";

const YuklenilecekKDVListesi = ({ tckn }) => {
  const [yuklenecekKDVData, setYuklenecekKDVData] = useState([]);
  const [exportDate, setExportDate] = useState({});
  const [missingProducts, setMissingProducts] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const axiosWithToken = useAxios();

  const getData = async (year, month) => {
    year = year ? year : exportDate?.year;
    month = month ? month : exportDate?.month;
    setError(false);
    setMissingProducts([]);
    if (year && month) {
      setExportDate({ year, month });
      setLoading(true);
      setYuklenecekKDVData([]);
      try {
        const { data } = await axiosWithToken(
          `api/yuklenilecek_kdv/yuklenim_arti_gcb_merge?tckn=${tckn}&year=${year}&month=${month}`
        );
        setYuklenecekKDVData(data.data);
        setMissingProducts(data.missing_products);
        setMessage(data.message);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error?.response?.data?.error);
        setYuklenecekKDVData([]);
        if (error?.response?.data?.missing_products?.length) {
          setMissingProducts(error?.response?.data?.missing_products);
        }
      }
    } else {
      notify("Lütfen önce tarih aralığı belirleyiniz!", "warning");
    }
  };
  useEffect(() => {
    setYuklenecekKDVData([]);
    setMissingProducts([]);
  }, [tckn]);
  return (
    <Box sx={{ p: 1 }}>
      <Typography color="error" variant="body2" mb={1}>
        *Eğer daha önce bu işlemleri yapmadıysanız lütfen önce yükleme
        işlemlerini tamamlayınız!
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          mt: 1,
          gap: 1,
          // maxWidth: "100%",
          // overflowY: "scroll",
        }}
      >
        <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          {" "}
          <EnvanterKaydi tckn={tckn} />
        </Box>
        <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          <GcbKaydi tckn={tckn} />{" "}
        </Box>
        <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          <YuklenimKaydi tckn={tckn} />{" "}
        </Box>
      </Box>

      <DateComp getData={getData} title={"Yüklenilecek KDV Listesi"} />
      {loading ? <LoadingData /> : ""}
      {error ? <ErrorData error={error} /> : ""}
      {error && error.includes("İmalat Ürün") ? (
        <Grid container mt={2} spacing={1}>
          <Grid item xs={10} md={8}>
            <UrunEslestirme currentTCKN={tckn} getData={getData} />
          </Grid>
          <Grid item xs={10} md={4}>
            <MissingProducts data={missingProducts} message={message} />
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {yuklenecekKDVData.length > 0 ? (
        <>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "secondary.second",
              p: 1,
              mt: 2,
            }}
          >
            <TableComponent
              data={yuklenecekKDVData}
              tableColumns={yuklenilecekKDVColumns}
            />
            <Grid container display={"flex"} justifyContent="end" spacing={1}>
              <Grid item>
                <ExportData
                  url={`api/yuklenilecek_kdv/yuklenim_arti_gcb_merge?tckn=${tckn}&year=${exportDate?.year}&month=${exportDate?.month}&export=excel`}
                  dosyaAdi={`yuklenilecek_KDV_listesi-${exportDate?.month}-${exportDate?.year}.xlsx`}
                />
              </Grid>
              <Grid item>
                <MyButton
                  title={"Kapat"}
                  variant="contained"
                  color="btnActiveColor"
                  onClick={() => setYuklenecekKDVData([])}
                >
                  Kapat
                </MyButton>
              </Grid>
            </Grid>
          </Box>
          <Grid container mt={2} spacing={1}>
            <Grid item xs={10} md={8}>
              <UrunEslestirme currentTCKN={tckn} getData={getData} />
            </Grid>
            <Grid item xs={10} md={4}>
              <MissingProducts data={missingProducts} message={message} />
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default YuklenilecekKDVListesi;
