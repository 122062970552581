import React, { useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import FullScreen from "../Commons/FullScreen";
import { useTheme } from "@mui/material";

const GlobalFilter = ({ filter, setFilter }) => {
  const theme = useTheme();
  return (
    <span
      style={{
        color: `${theme.palette.secondary.second}`,
      }}
    >
      Search:{" "}
      <input
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ fontSize: "1.1rem", marginBottom: "10px" }}
      />
    </span>
  );
};

const TableComponent = ({ data, tableColumns }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => tableColumns, []);
  const [filterInput, setFilterInput] = useState("");
  const theme = useTheme()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const handleFilterChange = (value) => {
    setGlobalFilter(value);
    setFilterInput(value);
  };

  if(!data?.length) return <h3>Görüntülenecek veri bulunamadı!</h3>

  return (
    <div>
      <FullScreen>
        <GlobalFilter filter={filterInput} setFilter={handleFilterChange} />
        <div
          className="myTable"
          style={{ overflowX: "auto", maxWidth: "100%" }}
        >
          <table
            {...getTableProps()}
            style={{
              width: "100%",
            }}
          >
            <thead
              style={{
                backgroundColor: "#0092CA",
                color: "white",
              }}
            >
              {headerGroups.map((headerGroup, hgIndex) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={`headerGroup-${hgIndex}`}
                >
                  {headerGroup.headers.map((column, colIndex) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={`column-${column.id}-${colIndex}`}
                      style={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        minWidth: "75px",
                        maxWidth: "150px",
                        cursor: "pointer",
                      }}
                      title={
                        typeof column.Header === "string" ? column.Header : ""
                      }
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "🔽" // Azalan sıralama sembolü
                            : "🔼" // Artan sıralama sembolü
                          : "⇅"}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              style={{
                color: `${theme.palette.secondary.second}`,
              }}
            >
              {page.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={`row-${rowIndex}`}>
                    {row.cells.map((cell, cellIndex) => (
                      <td
                        {...cell.getCellProps()}
                        key={`cell-${cell.column.id}-${cellIndex}`}
                        style={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                          minWidth: "75px",
                          maxWidth: "250px",
                        }}
                        title={cell.value} // Araç ipucu olarak hücre değerini göster
                      >
                        {cell.value ? cell.value : "-"}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div style={{ marginTop: "10px" }}>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span
            style={{
              color: `${theme.palette.secondary.second}`,
            }}
          >
            Sayfa{" "}
            <strong>
              {pageIndex + 1} / {pageOptions.length}
            </strong>{" "}
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Göster {pageSize}
              </option>
            ))}
          </select>
        </div>
      </FullScreen>
    </div>
  );
};

export default TableComponent;

