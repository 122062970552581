// import React from 'react'
// import { useSelector } from 'react-redux';
// import Select from "react-select";
// const customStyles = {
//   menuList: (provided, state) => ({
//     ...provided,
//     maxHeight: "250px",
//     overflow: "auto",
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     backgroundColor: state.isSelected
//       ? "#454F5B"
//       : state.isFocused
//       ? "#D3D3D3"
//       : "#fff",
//     color: state.isSelected ? "#fff" : "#454F5B",
//     padding: 10,
//     position: "relative",
//     zIndex: 25,
//   }),
// };
// const CustomerSelect = ({ selectedMusteri, setSelectedMusteri,msg }) => {
//     const {customers} = useSelector(state=>state.musavir)
//     const musteriSelect = customers?.map((item) => ({
//       value: item.tckn,
//       label: `${item.ad_soyad} - ${item.tckn}`,
//     }));
//   return (
//     <Select
//       value={selectedMusteri}
//       onChange={setSelectedMusteri}
//       options={musteriSelect}
//       isClearable={true}
//       styles={customStyles}
//       className="mb-3"
//       placeholder={msg}
//     />
//   );
// };

// export default CustomerSelect


import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Select from "react-select";
import { useGetCustomersQuery } from "../../services/customers";

const customStyles = {
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "250px",
    overflow: "auto",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#454F5B"
      : state.isFocused
      ? "#D3D3D3"
      : "#fff",
    color: state.isSelected ? "#fff" : "#454F5B",
    padding: 10,
    position: "static",
    zIndex: 1000,
  }),
};

const CustomerSelect = ({ selectedMusteri, setSelectedMusteri, msg }) => {
  const { data: customers } = useGetCustomersQuery();

  // Map customers to select options
  const musteriSelect = customers?.map((item) => ({
    value: item.tckn,
    label: `${item.ad_soyad} - ${item.tckn}`,
  }));

  // Set the default value to the first customer if the list is not empty
  useEffect(() => {
    if (customers && customers.length > 0 && !selectedMusteri) {
      setSelectedMusteri(musteriSelect[0]);
    }
  }, [customers, musteriSelect, setSelectedMusteri, selectedMusteri]);

  return (
    <Box sx={{ position: "relative",zIndex:5 }}>
      <Select
        value={selectedMusteri}
        onChange={setSelectedMusteri}
        options={musteriSelect}
        isClearable={true}
        styles={customStyles}
        className="mb-3"
        placeholder={msg}
      />
    </Box>
  );
};

export default CustomerSelect;
